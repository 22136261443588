* {
  font-size: 14px;
}
body{
  background: #fff;

}
#header{
  background: #004A90;
  color: #fff;
}

#header a{
  color: #fff;
}
.legenda_prenotazioni{
  width: 20px;
  height: 20px;
  border: 1px solid;
  display: inline-block;
}
#confermata{
  background: #4dd4fd;
}
#annullata{
  background: #fb613f;
}
#tabella_prenotazioni .gara_annullata td{
  background: #fb613f;
}
#tabella_prenotazioni{
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}
#tabella_prenotazioni>thead{
  vertical-align: middle;
}

#container_gestione{
  width: 80%;
}
#container_gestione #tabella_prenotazioni{
  margin-top: 6%;
  text-align: center;
  font-weight: 500;
}
#container_gestione #tabella_prenotazioni>thead{
  vertical-align: middle;
}

#tabella_controllo_disponibilita{
  width: auto;
}
#tabella_controllo_disponibilita thead{
  text-align: center;
}
#tabella_controllo_disponibilita tbody{
  text-align: left;
  font-weight: 500;
}
.alert{
  font-size: 16px;
  font-weight: 500;
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
  background: #D6EAF9;
}
.form-check-label{
  font-size: 16px;
}
.table .annullata td{
  background: #fb613f !important;
}
#filtro_ricerca{
  position: sticky;
  top: 0;
  background: #fff;
  padding-bottom: 20px;
  margin-top: 5%;
}
.btn{
  font-weight: bold;
  font-size: 15px;
}
label{
  font-weight: bold;
}
.form-control, .form-select{
  border: 2px solid #535556;
  font-weight: bold;
}
.list-group-item {
  border: 2px solid rgb(0 0 0);
  font-weight: bold;
  background: #004A90;
  color: #fff;
}
.list-group-item:hover{
  background: #CEF4FC;
}
.list-group-item:hover a{
  color: #000;
}
.list-group-item:last-child{
  text-align: center;
}
.list-group-item a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.list-group-item:last-child a {
  color: yellow;
  font-size: 19px;
}
.alert-info, .alert-warning strong{
  font-size: 18px;
}
#indietro{
  font-size: 21px;
}
@media (min-device-width: 240px) and (max-device-width: 767px) {
  #menu_azioni{
    margin-top: 30px;
  }
  #box_registrazione{
    margin-top: 30px;
  }
}